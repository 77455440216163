@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

.ff {
    font-family: "Urbanist" !important;
    font-weight: weight;
    font-style: normal;
}

.ff-alt {
    font-family: "Jost" !important;
    font-optical-sizing: auto;
    /* font-weight: weight; */
    font-style: normal;
}

.f-size {
    font-size: 40px;
    font-family: "Urbanist", sans-serif;
}

@media (max-width: 768px) {
    .f-size {
        font-size: 25px;
        font-family: "Urbanist", sans-serif;
    }

}

.bgimg {
    background-image: url("../images/axiom_img/background7flip.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* height: 100vh; */
}

@media (max-width: 576px) {
    .bgimg {
        height: auto;
        /* min-height: 100vh; */
        background-position: left center;
    }
}

/* For medium screens */
@media (min-width: 577px) and (max-width: 768px) {
    .bgimg {
        height: auto;
        min-height: 78vh;
        background-position: center center;
        object-fit: contain;
    }
}

/* For large screens */
@media (min-width: 769px) and (max-width: 992px) {
    .bgimg {
        height: 90vh;
        background-position: center center;
    }
}

/* For extra-large screens */
@media (min-width: 993px) {
    .bgimg {
        height: 100vh;
        background-position: center center;
    }
}

.home_logo {
    height: 60px;
    max-height: 60px;
    width: auto;
}

@media (max-width: 1024px) {
    .home_logo {
        height: 6vw;
        max-height: 60px;
    }
}

@media (max-width: 768px) {
    .home_logo {
        height: 5vw;
        max-height: 50px;
    }
}

@media (max-width: 425px) {
    .home_logo {
        height: 8vw;
        max-height: 40px;
    }
}

.content {
    font-size: medium;
    color: #2B3146;
}

.para3 {
    font-size: medium;
    display: flex;
    color: #2B3146;
}

.img {
    width: 80%;
    margin: auto;
    padding: 20px;
}

.socialbtn:hover {
    outline: none;
    transform: scale(1.3);
    transition: all 0.3s ease-in-out;
}

.accordlogo {
    width: 80px;
    height: 80px;

}

.servicehead {
    height: 650px;
    position: relative;
    z-index: 999;
}

.stats-container {
    padding: 20px;
    border-radius: 5px;
}

.stat-item {
    text-align: center;
}

.stat-value {
    font-size: 30px;
    font-weight: 900;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 5px;
    color: black;
}

.gradient-button {
    border: 0;
    font-family: 'Your-Font-Family', sans-serif;
    font-weight: bold;
    background-image: linear-gradient(to right, #2B4584 30%, #4A9E48 60%, #4DA44B 100%);
    transition: background-image 0.8s ease-in-out;
}

.gradient-button:hover {
    background-image: linear-gradient(to left, #2B4584 30%, #4A9E48 60%, #4DA44B 100%);
}

/* .textunderline {
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #5be374;
    transition: color 0.3s ease, transform 0.3s ease;
} */

/* .textunderline:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #5be374;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
} */

.navbar-nav .active {
    border-bottom: #5be374 3px solid;
}

/* .textunderline:hover {

    color: #5be374;

}

.textunderline:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
} */

.icon_img {
    left: -30%;
    top: 10%;
}

.icon_img2 {
    right: -15%;
    bottom: 35%;
}

.icon_img3 {
    right: -17%;
    bottom: 55%;
}

.icon_img4 {
    left: -11%;
    bottom: -18%;
    transform: scaleX(-1);
}

.icon_img5 {
    right: 8px;
    top: 5px;
    height: 37% !important;
}

.icon_img6 {
    right: 8px;
    top: 5px;
    height: 37% !important;
}

@media (max-width: 1200px) {
    .icon_img {
        left: -30%;
        top: 10%;
    }

    .icon_img2 {
        right: -12%;
        bottom: 10%;
    }

    .icon_img3 {
        right: 0%;
        bottom: 0%;
    }

    .icon_img4 {
        left: -10%;
        bottom: -20%;
        transform: scaleX(-1);
    }

    .icon_img5 {
        right: 8px;
        top: 5px;
        height: 70px !important;
    }

    .icon_img6 {
        right: 8px;
        top: 5px;
        height: 22% !important;
    }

}

@media (max-width: 1024px) {
    .icon_img {
        left: -25%;
        top: 60%;
    }

    .icon_img2 {
        right: -20%;
        bottom: 40%;
    }

    .icon_img3 {
        right: -17%;
        bottom: 65%;
    }

    .icon_img4 {
        left: -17%;
        bottom: -15%;
        transform: scaleX(-1);
    }

    .icon_img5 {
        right: 8px;
        top: 5px;
        height: 70px !important;
    }

    .icon_img6 {
        right: 8px;
        top: 5px;
        height: 22% !important;
    }

}

@media (max-width: 768px) {
    .icon_img {
        left: -35%;
        top: 60%;
    }

    .icon_img2 {
        right: -30%;
        bottom: 39%;
    }

    .icon_img3 {
        right: -25%;
        bottom: 60%;
    }

    .icon_img4 {
        left: -23%;
        bottom: -18%;
        transform: scaleX(-1);
    }

    .icon_img5 {
        right: 8px;
        top: 5px;
        height: 70px !important;
    }

    .icon_img6 {
        right: 8px;
        top: 5px;
        height: 18% !important;
    }
}

@media (max-width: 480px) {
    .icon_img5 {
        right: 8px;
        top: 5px;
        height: 70px !important;
    }
}

.responsive-image {
    width: 50%;
}

@media (min-width: 576px) {
    .responsive-image {
        width: 50%;
    }
}

@media (min-width: 768px) {
    .responsive-image {
        width: 50%;
    }
}

@media (min-width: 992px) {
    .responsive-image {
        width: 100%;
    }
}

/* For medium screens */

@media (max-width: 768px) {
    .servcont {
        padding-left: 5%;
    }

    .servicehead {
        height: 920px;
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media (max-width: 480px) {
    .img {
        width: 100%;
        padding: 10px;
    }

    .accordlogo {
        width: 60px;
        height: 60px;
    }

    .servicehead {
        height: auto;
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media (min-width: 768px) {
    .border-md-start {
        border-left-style: solid !important;
    }

    .border-md-2 {
        border-left-width: 2px !important;
    }

    .border-md-primary {
        border-left-color: #0251AE !important;
        /* Customize the primary color */
    }
}


.card1 {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;

}

.card__thumb {
    height: 180px;
    overflow: hidden;
    background-color: #ffffff;
    transition: height 0.5s;
}

.card__thumb img {
    width: 100%;
    display: block;
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s, transform 0.5s;
}

.card1:hover .card__thumb {
    height: 170px;
}

.card1:hover .card__thumb img {
    opacity: 0.6;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.card__body {
    position: relative;
    height: 145px;
    padding: 15px;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.card1:hover .card__body {
    height: 285px;
}

.card__title {
    margin: 0;
    padding: 0 0 10px 0;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.card__subtitle {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;

}

.card__description {
    margin: 0;
    padding: 0;
    color: #666C74;
    line-height: 27px;
    opacity: 0;
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.card1:hover .card__description {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

@media (max-width: 1024px) {
    .card1 {
        max-width: 100%;
    }

    .card__thumb {
        height: 127px;
    }

    .card1:hover .card__thumb {
        height: 115px;
    }

    .card__body {
        height: 130px;
    }

    .card1:hover .card__body {
        height: 298px;
    }

    .card__title,
    .card__subtitle {
        font-size: 16px;
    }

    .card__description {
        line-height: 20px;
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .card1 {
        max-width: 90%;
    }

    .card__thumb {
        height: 160px;
    }

    .card__body {
        height: 120px;
    }

    .card1:hover .card__body {
        height: 238px;
    }

    .card__title,
    .card__subtitle {
        font-size: 16px;
    }

    .card__description {
        line-height: 20px;
    }
}

@media (max-width: 480px) {
    .card1 {
        max-width: 100%;
    }

    .card__thumb {
        height: 170px;
    }

    .card__body {
        height: 120px;
    }

    .card1:hover .card__body {
        height: 275px;
    }

    .card__title,
    .card__subtitle {
        font-size: 18px;
    }

    .card__description {
        line-height: 20px;
    }
}

.A1 {
    display: inline-block;
    position: absolute;
    top: -10px;
    left: -2%;
    z-index: 0;
}

.A2 {
    display: inline-block;
    position: absolute;
    left: 0%;
    bottom: -2%;
    z-index: 0;
}

.A3 {
    position: absolute;
    right: -10%;
    top: -10%;
}

.A4 {
    display: inline-block;
    position: absolute;
    left: 0%;
    bottom: 15%;
    z-index: 0;
}

.cardimg {
    left: 50%;
    top: -50%;
    width: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
    .cardimg {
        left: 86%;
        top: 20%;
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .cardimg {
        left: 86%;
        top: 35%;
        width: 40%;
    }
}

@media (min-width: 1440px) {
    .cardimg {
        left: 85%;
        top: 17%;
        width: 40%;
    }
}

.button {
    height: 50px;
    margin: 5px;
    background: #00133D;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: Consolas, Courier New, monospace;
    border: solid #404c5d 1px;
    font-size: 16px;
    color: rgb(226, 226, 226);
    -webkit-transition: 500ms;
    transition: 500ms;
    border-radius: 5px;
    background: linear-gradient(145deg, #00133D, #00133D);
    /* box-shadow: 0px -1px 7px #41465b, 2px 2px 7px #41465b,
        inset 5px 5px 10px #00133D, inset -5px -5px 10px #00133D; */
}

.button:hover {
    -webkit-box-shadow: 1px 1px 13px #00133D, -1px -1px 13px #545b78;
    box-shadow: 1px 1px 13px #00133D, -1px -1px 13px #545b78;
    color: #d6d6d6;
    -webkit-transition: 500ms;
    transition: 500ms;
    transform: scale(1.1);
}

.button:active {
    -webkit-box-shadow: 1px 1px 13px #20232e, -1px -1px 33px #545b78;
    box-shadow: 1px 1px 13px #20232e, -1px -1px 33px #545b78;
    color: #d6d6d6;
    -webkit-transition: 100ms;
    transition: 100ms;
}

.image-shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.borderbottom {
    border-bottom: 2px solid #1732e0;
    position: absolute;
    top: 50%;
    bottom: 0;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.card-appear {
    animation: slideInFromRight 1.3s forwards;
}

.screen {
    display: block;
    width: 100%;
    max-width: 300px;
    height: 0;
    padding-bottom: 116.67%;
    /* Maintains the aspect ratio */
    overflow: hidden;
    /* Prevents the image from overflowing the container */
    position: relative;
    border: 2px solid #b3b3b3;
    border-radius: 10px;
    margin: 0 auto;
    transition: box-shadow 0.3s ease-in-out;

}

.screen:hover {
    box-shadow: 0px 2px 62px 0px rgba(0, 0, 0, 0.2);
}

.screen img {
    position: absolute;
    top: 0;
    /* Image starts fully visible at the bottom */
    width: 100%;
    height: auto;
    z-index: 0;
    margin: 0;
    padding: 0;
    transition: transform 5s ease-in-out;
    /* Smooth transition for moving the image */
}

.screen:hover img {
    transform: translateY(-60%);
    /* Moves the image up by its full height on hover */
}

.service-gallery {
    position: relative;
}

@media (max-width: 767px) {
    .service-gallery {
        height: calc(100vh - 150px);
        /* Adjust based on your layout */
        overflow: hidden;
    }

    .mobile-scroll {
        height: 100%;
        overflow-y: auto;
    }
}